@import 'common';
@import 'bootstrap/scss/breadcrumb';

.breadcrumb-item {
	position: relative;
	display: flex;
	text-transform: uppercase;
	@include responsiveFont($type-p-xs);
	margin-bottom: 0;
	height: 48px;
	align-items: center;

	@include media-breakpoint-down(md) {
		&:not(:nth-last-child(2)) {
			display: none;
		}
		& + & {
			padding-left: 0;
			&::before {
				transform: rotate(-180deg);
			}
		}
	}

	& + &::before {
		width: 16px;
		height: 16px;
		margin-right: map-get($spacers, '8p');
		padding: 0;
		@include lightTheme() {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICAgIDxwYXRoIGZpbGw9IiMxMTEiIGNsYXNzPSJmaWxsLXByaW1hcnkiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE5LjE4MyAxNS45MjNMMTIgOC43NGwuNzAzLS43NCA3LjkyNCA3LjkyMy03LjkyNCA3Ljk2LS43MDMtLjc0eiIvPgo8L3N2Zz4K);
		}
		@include darkTheme() {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiIGNsYXNzPSJmaWxsLXByaW1hcnkiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE5LjE4MyAxNS45MjNMMTIgOC43NGwuNzAzLS43NCA3LjkyNCA3LjkyMy03LjkyNCA3Ljk2LS43MDMtLjc0eiIvPgo8L3N2Zz4K);
		}
	}

	.breadcrumb & a {
		position: relative;
		@include link-underline($headings-color, $headings-color-inverted);
		@include tap-area-selector(true, ':after');

		@include lightTheme {
			color: $body-color;
		}
		@include darkTheme {
			color: $body-color-inverted;
		}

		&:hover {
			@include lightTheme {
				color: $headings-color;
			}
			@include darkTheme {
				color: $headings-color-inverted;
			}
			@extend %link-underline-hover;
		}
	}
}

.breadcrumb {
	max-width: remsize(940);
	.breadcrumb-item:first-of-type a {
		display: inline-flex;
		position: relative;
		width: 16px;
		height: 16px;
		opacity: 0.6;

		&:hover,
		&:active {
			opacity: 0.95;
		}

		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			@include lightTheme() {
				@include home-icon($black);
			}
			@include darkTheme() {
				@include home-icon($white);
			}
		}
	}
}
